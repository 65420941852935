<template>
  <h2>Contact formulier</h2>
  <div id="contact-form" class="contact-form">
    <form id="contactform" @submit.prevent="submit" @reset="onReset">
      <fieldset>
        <input required name="subject" id="subject" v-model="subject" placeholder="Onderwerp" />
        <div>
          <input required name="name" id="name" v-model="name" placeholder="Voornaam" />
          <input required name="lastname" id="lastname" v-model="lastname" placeholder="Achternaam" />
        </div>
        <input required name="email" id="email" v-model="email" placeholder="E-mail" />
        <input name="phone" id="phone" v-model="phone" placeholder="Telefoonnummer" />
        <textarea required name="message" id="message" v-model="message" placeholder="Bericht"></textarea>
      </fieldset>
      <button type="submit">Verzenden</button>
      <div id="formmesssage" class="alert hide" role="alert">
      </div>
    </form>
  </div>
</template>

<script>
  import $ from 'jquery';
  var processresult = function (result){
    var jsonData = JSON.parse(result)
    $('#formmesssage').removeClass("alert-success");
    $('#formmesssage').removeClass("alert-danger");
    $('#formmesssage').removeClass("hide");
    $('#formmesssage').html(jsonData.message);
    
    if (jsonData.success) {             
      $('#formmesssage').addClass("alert-success");
      
    } else {
      $('#formmesssage').addClass("alert-danger");
    }
  }

  export default {
    name: "App",
    data() {
      return {
        subject: "",
        name: "",
        lastname: "",
        email: "",
        phone: "",
        message: "",
      };
    },
    computed: {
      formValid() {
        const { subject, name, lastname, email, message } = this;
        return (
          subject.length > 0 &&
          name.length > 0 &&
          lastname.length > 0 &&
          /(.+)@(.+){2,}.(.+){2,}/.test(email) &&
          message.length > 0
        );
      },
    },
    methods: {
      onReset() {
        this.subject = "";
        this.name = "";
        this.lastname = "";
        this.email = "";
        this.phone = "";
        this.message = "";
      },
      submit() {
        if (!this.formValid) {
          return;
        }
        const { subject, name, lastname, email, phone, message } = this;
        $.ajax({
          url: "https://liefslune.nl/mail/contact_me.php",
          type: "POST",
          data: {subject: subject, name: name, lastname: lastname, email: email, phone: phone, message: message},
          cache: false,
          success: function(data) {
            processresult(data);
            $('#contactform').trigger("reset");
          },
          error: function() {
            var data = '{"success":false,"message":"Verzenden mislukt, probeer het later nog eens of stuur een email.","errors":true}';
            processresult(data);
          },
        })
      },
    },
  };
</script>